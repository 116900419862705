function isMSbrowser() {
    const userAgent = window.navigator.userAgent;
    return userAgent.indexOf("Edge") !== -1 || userAgent.indexOf("Trident") !== -1;
}

function format(data) {
    return String(data)
        .replace(/"/g, '""')
        .replace(/(^[\s\S]*$)/, '"$1"');
}

export function saveCSV(
    title = "檔名",
    head = ["Title1", "Title2"],
    data = [
        ["val1-1", "val2-1"],
        ["val2-1", "val2-2"],
    ]
) {
    const wordSeparator = ",";
    const lineSeparator = "\n";

    const reTitle = title + ".csv";
    const headBOM = "\ufeff";
    const headStr = head ? head.map((item) => format(item)).join(wordSeparator) + lineSeparator : "";
    const dataStr = data ? data.map((row) => row.map((item) => format(item)).join(wordSeparator)).join(lineSeparator) : "";

    return isMSbrowser()
        ? new Promise((resolve) => {
              // Edge、IE11
              const blob = new Blob([headBOM + headStr + dataStr], {
                  type: "text/plain;charset=utf-8",
              });
              window.navigator.msSaveBlob(blob, reTitle);
              resolve();
          })
        : new Promise((resolve) => {
              // Chrome、Firefox
              const a = document.createElement("a");
              a.href = "data:text/csv;charset=utf-8," + headBOM + encodeURIComponent(headStr + dataStr);
              a.download = reTitle;
              a.click();
              resolve();
          });
}
