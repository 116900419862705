// 訂單狀態
const orderStatus = {
    1: "等待確認",
    2: "已確認",
    3: "進行中",
    4: "已完成",
    6: "爭議處理完成",
    "-1": "服務商取消",
    "-2": "會員取消",
    "-3": "會員臨時取消",
    "-4": "爭議處理中",
};

// 會員金流紀錄類型
const transactionType = {
    TOP_UP: "儲值",
    CREATE_DATING: "建立訂單",
    UPDATE_DATING: "修改訂單",
    CANCEL_DATING: "取消訂單",
    REJECT_DATING: "臨時取消",
    REFUSE_DATING: "訂單婉拒",
    PREPARE_DATING: "訂單預扣",
    CANCEL_PREPARED_DATING: "預扣返還",
    CREATE_DATING_EXTENSION: "建立續約",
    UPDATE_DATING_EXTENSION: "修改續約",
    CANCEL_DATING_EXTENSION: "取消續約",
    REFUSE_DATING_EXTENSION: "續約婉拒",
    PREPARE_DATING_EXTENSION: "續約預扣",
    CANCEL_PREPARED_DATING_EXTENSION: "預扣返還",
    REFUND_DATING: "退款",
    SETTLE_DATING: "訂單入帳",
    WITHDRAW: "立即提領",
    CHARGE_OFF: "月結款項",
    REFUND: "新增餘額",
    DEDUCTION: "扣除餘額",
};

// 會員金流紀錄類型
const transactionUserType = {
    TOP_UP: "儲值",
    CREATE_DATING: "建立訂單",
    UPDATE_DATING: "修改訂單",
    CANCEL_DATING: "取消訂單",
    REJECT_DATING: "臨時取消",
    REFUSE_DATING: "訂單婉拒",
    PREPARE_DATING: "訂單預扣",
    CANCEL_PREPARED_DATING: "預扣返還",
    CREATE_DATING_EXTENSION: "建立續約",
    UPDATE_DATING_EXTENSION: "修改續約",
    CANCEL_DATING_EXTENSION: "取消續約",
    REFUSE_DATING_EXTENSION: "續約婉拒",
    PREPARE_DATING_EXTENSION: "續約預扣",
    REFUND_DATING: "退款",
    REFUND: "新增餘額",
    DEDUCTION: "扣除餘額",
};

// 服務商金流紀錄類型
const transactionProviderType = {
    REJECT_DATING: "臨時取消",
    SETTLE_DATING: "訂單入帳",
    WITHDRAW: "立即提領",
    CHARGE_OFF: "月結款項",
    REFUND: "新增餘額",
    DEDUCTION: "扣除餘額",
};

// 續約單狀態
const extensionStatus = {
    1: "等待確認",
    2: "已確認",
    "-1": "服務商取消",
    "-2": "會員取消",
};

// 快閃折抵金狀態
const paymentDiscountEvent = {
    1: "發放快閃折抵金",
    2: "新增快閃折抵金",
    3: "退還快閃折抵金",
    4: "使用快閃折抵金",
    5: "扣除快閃折抵金",
    6: "快閃折抵金過期",
};

// 快閃折抵金狀態
const voucherTypes = {
    EARN: "發放快閃折抵金",
    INCREASE: "新增快閃折抵金",
    REFUND: "退還快閃折抵金",
    BURN: "使用快閃折抵金",
    DEDUCT: "扣除快閃折抵金",
    EXPIRE: "快閃折抵金過期",
};

export default {
    orderStatus,
    transactionUserType,
    transactionProviderType,
    transactionType,
    extensionStatus,
    paymentDiscountEvent,
    voucherTypes,
};
